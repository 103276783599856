import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import QuadroObreiroPage from './Components/QuadroObreiroPage';
import IniciacaoPage from './Components/IniciacaoPage';
import AniversarioPage from './Components/AniversarioPage';
import MemoriasFotograficasPage from './Components/MemoriasFotograficasPage';
import IframePage from './Components/IframePage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quadro-obreiro" element={<QuadroObreiroPage />} />
        <Route path="/iniciacao" element={<IniciacaoPage />} />
        <Route path="/aniversario" element={<AniversarioPage />} />
        <Route path="/memorias-fotograficas" element={<MemoriasFotograficasPage />} />
        <Route path="/gestao-anterior" element={<IframePage src="https://estrelacaldense.com.br/novo_app/GestoesAnteriores.html" />} />
        <Route path="/nominata" element={<IframePage src="https://estrelacaldense.com.br/novo_app/nominata.html" />} />
        <Route path="/regimento-interno" element={<IframePage src="https://estrelacaldense.com.br/novo_app/baixarpdf.html" />} />
        <Route path="/corpos" element={<IframePage src="https://estrelacaldense.com.br/novo_app/corposfilosoficos.html?v=1234" />} />
        <Route path="/fundadores" element={<IframePage src="https://estrelacaldense.com.br/novo_app/fundadores.html?v=1234" />} />
        <Route path="/veneraveis-mestres" element={<IframePage src="https://estrelacaldense.com.br/novo_app/veneraveis.html?v=1234" />} />
        <Route path="/nossas-escolas" element={<IframePage src="https://estrelacaldense.com.br/novo_app/escolas.html?v=1234" />} />
        <Route path="/nossa-creche" element={<IframePage src="https://estrelacaldense.com.br/novo_app/creches.html?v=1234" />} />
        <Route path="/entidades" element={<IframePage src="https://estrelacaldense.com.br/novo_app/arcoiris.html?v=1234" />} />
        <Route path="/creditos" element={<IframePage src="https://estrelacaldense.com.br/novo_app/creditos.html?v=1234" />} />
      </Routes>
    </Router>
  );
};

export default App;
